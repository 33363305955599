// import { Style, Fill, Stroke, Circle } from "ol/style";
import { Vector as VectorSource } from "ol/source";
import { Vector as VectorLayer, Group } from "ol/layer";
import { pointStyle, 
  // circleStyle 
} from "../Styles/OLStyles";
import OSM from 'ol/source/OSM';
import OLTileLayer from "ol/layer/Tile";

import { fromLonLat } from "ol/proj";
import Feature from "ol/Feature";
// import Geometry from "ol/geom/Geometry";
// import Polygon from "ol/geom/Polygon";
// import MultiPoint from "ol/geom/MultiPoint";
// import LineString from "ol/geom/LineString";
// import Circle from "ol/geom/Circle";
import Point from "ol/geom/Point";

//// LAYERS GROUPS
const layersGroupObject = {
  map:{
    center: fromLonLat([144.9701842, -37.80758647]),
    zoom: 19,
    projection: "EPSG:3857",
    scaleType: "scaleline",
    scaleBarSteps: 4,
    scaleBarText: true,
  
    scaleUnits: 'metric',
    scaleSteps: 4,
    scaleText: true,
    scaleMinWidth: 140,
  },
  tilegroup: [
    new Group({
      title: "Baselayers",
      control: "checkbox",
      selectAll: true,
      selectAllOnly: false,
      visible: true,
      layers: [
        new OLTileLayer({
          source: new OSM(),
          zIndex: 0,
          title: "Open Street Map",
          visibility: true,
        }),
      ],
    }),
  ],
  featuregroups: [
    new Group({
      title: "Melbourne North PM25",
      type: "feature",
      control: "checkbox",
      selectAll: true,
      selectAllOnly: false,
      visible: true,
      layers: [
        new VectorLayer({
          source: new VectorSource({
            attributions: "SMES",
            features: [
              new Feature({
                geometry: new Point(fromLonLat([144.9712336, -37.80741])),
              }),
            ],
          }),
          colors: { stroke: "#000", fill: "#000" },
          zIndex: 3,
          visible: true,
          title: "PM25_SMES",
          style: pointStyle({ stroke: "#000", fill: "#000" }),
        }),
        new VectorLayer({
          source: new VectorSource({
            attributions: "SMES",
            features: [],
          }),
          datafolder: "./Data/",
          filename: "pm25_trimble.json",
          colors: { stroke: "#000", fill: "#000" },
          zIndex: 3,
          visible: true,
          title: "PM25_Trimble",
          style: pointStyle({ stroke: "#F00", fill: "#F00" }),
        }),
        new VectorLayer({
          source: new VectorSource({
            attributions: "SMES",
            features: [],
          }),
          datafolder: "./Data/",
          filename: "pm25_mobile.json",
          stddev: "PM25_UOM StdDev",
          colors: { stroke: "#F00", fill: "#00F" },
          zIndex: 3,
          visible: true,
          title: "PM25_UOM",
          style: pointStyle({ stroke: "#F00", fill: "#00F" }),
        }),
        new VectorLayer({
          source: new VectorSource({
            attributions: "SMES",
            features: [],
          }),
          type: "mean",
          colors: { stroke: "#F00", fill: "#00F" },
          zIndex: 2,
          visible: true,
          title: "PM25_UOM StdDev",
          dataFrom: "PM25_UOM",
        }),
      ],
    }),
    new Group({
      title: "Melbourne North PM24",
      type: "feature",
      control: "checkbox",
      selectAll: true,
      selectAllOnly: false,
      visible: true,
      layers: [
        new VectorLayer({
          source: new VectorSource({
            attributions: "SMES",
            features: [
              new Feature({
                geometry: new Point(fromLonLat([144.9701842, -37.80758647])),
              }),
            ],
          }),
          colors: { stroke: "#000", fill: "#000" },
          zIndex: 3,
          visible: true,
          title: "PM24_SMES",
          style: pointStyle({ stroke: "#000", fill: "#000" }),
        }),
        new VectorLayer({
          source: new VectorSource({
            attributions: "SMES",
            features: [],
          }),
          datafolder: "./Data/",
          filename: "pm24_mobile.json",
          stddev: "PM24_UOM StdDev",
          colors: { stroke: "#F00", fill: "#0F0" },
          zIndex: 3,
          visible: true,
          title: "PM24_UOM",
          style: pointStyle({ stroke: "#F00", fill: "#0F0" }),
        }),
        new VectorLayer({
          source: new VectorSource({
            attributions: "SMES",
            features: [],
          }),
          type: "mean",
          colors: { stroke: "#F00", fill: "#0F0" },
          zIndex: 6,
          visible: true,
          title: "PM24_UOM StdDev",
          dataFrom: "PM24_UOM",
        }),
      ],
    }),
  ],
};

export default layersGroupObject
