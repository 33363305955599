import { Fill, Stroke, Circle, Style } from "ol/style";

const pointStyle = ({ stroke, fill, radius = 2 }) =>
  new Style({
    image: new Circle({
      fill: new Fill({
        color: fill,
      }),
      radius: radius,
      stroke: new Stroke({
        color: stroke,
        width: 1,
      }),
    }),
  });

const circleStyle = ({ stroke, fill }) =>
  new Style({
    renderer(coordinates, state) {
      const [[x, y], [x1, y1]] = coordinates;
      const ctx = state.context;
      const dx = x1 - x;
      const dy = y1 - y;
      const radius = Math.sqrt(dx * dx + dy * dy);

      const innerRadius = 0;
      const outerRadius = radius * 1.4;

      const gradient = ctx.createRadialGradient(
        x,
        y,
        innerRadius,
        x,
        y,
        outerRadius
      );
      gradient.addColorStop(0, hexToRgbA(fill, 0));
      gradient.addColorStop(0.6, hexToRgbA(fill, 0.2));
      gradient.addColorStop(1, hexToRgbA(fill, 0.8));
      ctx.beginPath();
      ctx.arc(x, y, radius, 0, 2 * Math.PI, true);
      ctx.fillStyle = gradient;
      ctx.fill();

      ctx.arc(x, y, radius, 0, 2 * Math.PI, true);
      ctx.strokeStyle = hexToRgbA(stroke, 1);
      ctx.stroke();
    },
  });

// const iconStyle = ({ icon }) =>
//   new Style({
//     image: new Icon({
//       anchor: [0.5, 46],
//       anchorXUnits: "fraction",
//       anchorYUnits: "pixels",
//       src: icon,
//     }),
//   });

export { pointStyle, circleStyle };

const hexToRgbA = (hex, transparency) => {
  var c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split("");
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = "0x" + c.join("");
    // return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+',1)';
    return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(
      ","
    )},${transparency})`;
  }
  throw new Error(`Bad Hex ${hex}`);
};

// const hexToRgb = (hex) => {
//   var c;
//   if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
//     c = hex.substring(1).split("");
//     if (c.length === 3) {
//       c = [c[0], c[0], c[1], c[1], c[2], c[2]];
//     }
//     c = "0x" + c.join("");
//     return "rgb(" + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") + ")";
//   }
//   throw new Error("Bad Hex");
// };
