import React, { useState } from "react";
import "../Controls/Controls.css";
import MenuIcon from "./MenuIcon";

const MenuOverlay = (props) => {
  const [open, toggleMenu] = useState(true);

  return (
    <>
      <MenuIcon
        img={"../img/layers.png"}
        className="open_menu"
        alt="Layers"
        onClick={(e) => {
          e.preventDefault();
          toggleMenu(true);
        }}
      />

      {open && (
        <div className="overlay">
          <div className="close_menu">
            <div
              onClick={(e) => {
                e.preventDefault();
                console.log("You clicked submit.");
                toggleMenu(false);
              }}
            >
              X
            </div>
          </div>
          {props.children}
        </div>
      )}
    </>
  );
};

export default MenuOverlay;
