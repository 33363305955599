import { useState, useContext, useEffect } from "react";
import MapContext from "../Map/MapContext";
import MenuOverlay from "./MenuOverlay";
import CheckBox from "./CheckBox";
import ZoomToLayer from "./ZoomToLayer";

const LayersMenu = () => {
  const { map } = useContext(MapContext);
  const [featureGroups, setFetureGroups] = useState([]);

  //// ADD FEATURE LAYERS GROUPS TO THE STATE FOR VISIBILITY CONTROL
  useEffect(() => {
    if (!map) return;
    const featureGroups = map
      .getLayers()
      .getArray()
      .filter((g) => g.get("type") === "feature");
    setFetureGroups(featureGroups);
  }, [map]);

  return (
    <MenuOverlay>
      <div className="layers_menu_content">
        {
          //// GROUPS VISIBILITY
          featureGroups.map((group, i) => (
            <CheckBox
              key={i}
              className="group_toggle"
              type="checkbox"
              checked={group.get("visible")}
              onChange={(event) => {
                group.set("visible", event.target.checked);
                let newData = [...featureGroups];
                newData[i] = group;
                setFetureGroups(newData);
              }}
              label={group.get("title")}
            >
              {
                //// LAYERS VISIBILITY
                group
                  .getLayers()
                  .getArray()
                  .map((layer, j) => (
                    <>
                      <CheckBox
                        key={j}
                        className="layer_toggle"
                        type="checkbox"
                        checked={layer.get("visible")}
                        label={layer.get("title")}
                        onChange={(event) => {
                          layer.set("visible", event.target.checked);
                          let newData = [...featureGroups];
                          newData[i] = group;
                          setFetureGroups(newData);
                        }}
                      >
                        <ZoomToLayer
                          className={"zoom_to_layer"}
                          onClick={(event) => {
                            map.getView().fit(layer.getSource().getExtent());
                          }}
                        />
                      </CheckBox>
                    </>
                  ))
              }
            </CheckBox>
          ))
        }
      </div>
    </MenuOverlay>
  );
};

export default LayersMenu;
