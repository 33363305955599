import React, { useState, useEffect } from "react";
import * as d3 from "d3";
// import _ from "lodash";
import * as olProj from "ol/proj";
import { getPointResolution } from "ol/proj";
import { fromLonLat } from "ol/proj";
import Feature from "ol/Feature";
// import Geometry from "ol/geom/Geometry";
// import Polygon from "ol/geom/Polygon";
// import MultiPoint from "ol/geom/MultiPoint";
// import LineString from "ol/geom/LineString";
import Circle from "ol/geom/Circle";
import Point from "ol/geom/Point";

import { Map } from "../Map";
import { LayersGroup } from "../Layers";
import { Scale, MapControls, FullScreenControl, LayersMenu } from "../Controls";
import { pointStyle, circleStyle } from "../Styles/OLStyles";
import "../App.css";
import {
  getFetureLayers,
  createFeatureFromJSON,
  getLayersByType,
  getStdDev,
  getMean,
  readData,
  findLayerBy,
  getAllLayers,
} from "../Helpers/helpers";

//// IMPORT DATA OBJECT
import layersGroupObject from "./PositioningMap_Data";

const PositioningTab = () => {
  //// SET MAP STATE. THE MAP WILL BE SET INTHE Map COMPONENT AS MapContext.
  const [map, setMap] = useState(null);
  const center = layersGroupObject.map.center;
  const zoom = layersGroupObject.map.zoom;
  const projID = layersGroupObject.map.projection;

  //// GET AND PROCESS DATA, ADD DATA TO FEATURE LAYERS AND CALCULATED LAYERS IN LAYERS GROUP OBJECT
  const [data, setData] = useState(null);
  const featureGroups = layersGroupObject.featuregroups;
  const tileGroup = layersGroupObject.tilegroup[0]; ////// need to use [0] as we will not iterate it

  const processLayersData = (groups, map) => {
    // READ DATA WITH D3JS AND ADD FEATURES
    const featureLayers = getFetureLayers(groups);
    const meanLayers = getLayersByType(groups, "mean");
    featureLayers.forEach((layer) => {
      const dataFolder = layer.get("datafolder");
      const filename = layer.get("filename");
      const title = layer.get("title");

      readData(title, dataFolder + filename, console.log);

      let url;
      if (filename.split(".")[1] === "csv") {
        url = d3.csv(dataFolder + filename);
      } else if (filename.split(".")[1] === "xml") {
        url = d3.xml(dataFolder + filename);
      } else {
        url = d3.json(dataFolder + filename);
      }
      url
        .then(
          function (data) {
            return {
              success: true,
              title: title,
              filename: filename,
              data: data,
            };
          },
          function () {
            return { success: false };
          }
        )
        .then(function (d) {
          //// ADD DATA TO LAYER AS A PROPERTY
          layer.set("data", d.data);
          const newFeatures = d.data.features.map((f) =>
            createFeatureFromJSON(f)
          );

          //// ADD FEATURES TO THE LAYER
          layer.getSource().addFeatures(newFeatures);

          //// CREATE MEAN POSITION LONLAT AND STD DEV ON UTM
          const utm = layer.get("data").features[0].properties.utm;
          const stdDev = getStdDev(utm);
          const coords = layer.get("data").features[0].geometry.coordinates;
          const mean = fromLonLat(getMean(coords));

          //// ADD CIRCLE FEATURE TO StdDev LAYER
          const StdDevLayer = meanLayers.filter(
            (l) => l.get("dataFrom") === layer.get("title")
          )[0];
          if (StdDevLayer) {
            //// convert stdDev to pixels at this point of the map
            const view = map.getView();
            const projection = view.getProjection();
            const resolutionEq = view.getResolution();
            const pointResolution = getPointResolution(
              projection,
              resolutionEq,
              mean
            );
            const resolutionFactor = resolutionEq / pointResolution;
            const radius =
              (stdDev / olProj.METERS_PER_UNIT.m) * resolutionFactor;

            const colors = StdDevLayer.get("colors");
            const circleFeature = new Feature({
              geometry: new Circle(mean, radius),
            });
            circleFeature.setStyle(circleStyle(colors));

            const centerFeature = new Feature({
              geometry: new Point(mean),
            });
            centerFeature.setStyle(pointStyle("#000", "#000"));
            StdDevLayer.getSource().addFeatures([circleFeature, centerFeature]);
          }
        });
    });
    return groups;
  };

  // const processLayersData_2 = (data) => {
  //   console.log(data.layer);
  //   //// ADD DATA TO LAYER AS A PROPERTY AND ADD FEATURES TO THE LAYER
  //   const layer = data.layer
  //   layer.set("data", data.data);
  //   const newFeatures = data.data.features.map((f) => createFeatureFromJSON(f));
  //   layer.getSource().addFeatures(newFeatures);

  //   //// CREATE MEAN POSITION LONLAT AND STD DEV ON UTM
  //   const utm = layer.get("data").features[0].properties.utm;
  //   const stdDev = getStdDev(utm);
  //   const coords = layer.get("data").features[0].geometry.coordinates;
  //   const mean = fromLonLat(getMean(coords));

  //   //// ADD CIRCLE FEATURE TO StdDev LAYER
  //   const featureLayers = featureGroups.map(f => f.getLayers().getArray()).flat()
  //   const StdDevLayer = featureLayers.filter(x => x.get('title') == layer.get('stddev'))[0]
  //   console.log(StdDevLayer)
  //   if (StdDevLayer) {
  //     //// convert stdDev to pixels at this point of the map
  //     const view = map.getView();
  //     const projection = view.getProjection();
  //     const resolutionEq = view.getResolution();
  //     const pointResolution = getPointResolution(
  //       projection,
  //       resolutionEq,
  //       mean
  //     );
  //     const resolutionFactor = resolutionEq / pointResolution;
  //     const radius = (stdDev / olProj.METERS_PER_UNIT.m) * resolutionFactor;

  //     const colors = StdDevLayer.get("colors");
  //     const circleFeature = new Feature({
  //       geometry: new Circle(mean, radius),
  //     });
  //     circleFeature.setStyle(circleStyle(colors));

  //     const centerFeature = new Feature({
  //       geometry: new Point(mean),
  //     });
  //     centerFeature.setStyle(pointStyle("#000", "#000"));
  //     StdDevLayer.getSource().addFeatures([circleFeature, centerFeature]);
  //   }
  // };
  useEffect(() => {
    if (map !== null) {
      const d = processLayersData(featureGroups, map);
      setData(d);

      // const featureLayers = getFetureLayers(featureGroups);
      // const meanLayers = getLayersByType(featureGroups, "mean");

      // featureLayers.forEach((layer) => {
      //   const path = layer.get("datafolder") + layer.get("filename");
      //   readData(layer, path, processLayersData_2);
      // });
    }
  }, [map]);

  return (
    <div className="container">
      <Map center={center} projID={projID} zoom={zoom} onMount={setMap}>
        {map !== null && (
          <>
            <LayersGroup group={tileGroup} />
            {data !== null && (
              <>
                {data.map((group, index) => {
                  return <LayersGroup key={index} group={group} />;
                })}
                <LayersMenu />
              </>
            )}

            <MapControls>
              <FullScreenControl />
              <Scale />
            </MapControls>
          </>
        )}
      </Map>
    </div>
  );
};

export default PositioningTab;
