import React, { useEffect, useState } from "react";
import "../Controls/Controls.css";

const PopupOverlay = ({ feature }) => {
  return (
    <>
      {feature !== null && (
        <div className="overlay">
          {feature.map((e) => (
            <p>
              <bold>{e[0]}: </bold>
              {e[1]}
            </p>
          ))}
        </div>
      )}
    </>
  );
};

export default PopupOverlay;
