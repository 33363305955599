import * as d3 from "d3";
import Feature from "ol/Feature";
// import Geometry from "ol/geom/Geometry";
import Polygon from "ol/geom/Polygon";
import MultiPoint from "ol/geom/MultiPoint";
import LineString from "ol/geom/LineString";
import Point from "ol/geom/Point";
// import { PopupOverlay } from "../Controls";

const createFeatureFromJSON = (feature) => {
  ///// CREATE OL FEATURE FROM GEOJSON FEATURE
  ///// CREATE FEATURE AND ADD PROPERTIES
  var f = new Feature();
  for (var key in feature.properties) {
    f.set(key, feature.properties[key]);
  }
  ///// SELECT GEOMETRY OF FEATURE DEPENDING ON DATA
  if (feature.geometry.type === "Polygon") {
    f.setGeometry(new Polygon(feature.geometry.coordinates));
  } else if (feature.geometry.type === "MultiPoint") {
    f.setGeometry(new MultiPoint(feature.geometry.coordinates));
  } else if (feature.geometry.type === "LineString") {
    f.setGeometry(new LineString(feature.geometry.coordinates));
  } else {
    f.setGeometry(new Point(feature.geometry.coordinates));
  }
  //// TRANSFORM GEOMETRY
  f.getGeometry().transform("EPSG:4326", "EPSG:3857");
  return f;
};

const createFeatureFromCSV = (feature) => {
  ///// CREATE OL FEATURE FROM GEOJSON FEATURE
  ///// CREATE FEATURE AND ADD PROPERTIES
  var f = new Feature();
  for (const [key, value] of Object.entries(feature)) {
    f.set(key, value);
  }
  f.setGeometry(new Point([feature["Lon"], feature["Lat"]]));
  // //// TRANSFORM GEOMETRY
  f.getGeometry().transform("EPSG:4326", "EPSG:3857");
  return f;
};

const getFilenames = (groups) => {
  let filenames = [];
  groups.forEach((g) => {
    return filenames.push(
      ...g
        .getLayers()
        .getArray()
        .map((layer) => layer.get("filename"))
    );
  });

  filenames = [...new Set(filenames.filter((f) => f !== undefined))];
  return filenames;
};

const getFetureLayers = (groups) => {
  let layers = [];
  groups.forEach((g) => {
    return layers.push(
      ...g
        .getLayers()
        .getArray()
        .filter((layer) => layer.get("filename") !== undefined)
    );
  });
  return layers;
};

const getLayersByType = (groups, type) => {
  let layers = [];
  groups.forEach((g) => {
    return layers.push(
      ...g
        .getLayers()
        .getArray()
        .filter((layer) => layer.get("type") === type)
    );
  });
  return layers;
};

const getStdDev = (array) => {
  let UTMmean = [
    array.map((x) => +x[0]).reduce((a, b) => a + b, 0) / array.length,
    array.map((x) => +x[1]).reduce((a, b) => a + b, 0) / array.length,
  ];
  const sumsq = array
    .map((x) => (x[0] - UTMmean[0]) ** 2 + (x[1] - UTMmean[1]) ** 2)
    .reduce((a, b) => a + b, 0);
  const stdDev = (sumsq / array.length) ** 0.5;

  return stdDev;
};

const getMean = (array) => {
  return [
    array.map((x) => +x[0]).reduce((a, b) => a + b, 0) / array.length,
    array.map((x) => +x[1]).reduce((a, b) => a + b, 0) / array.length,
  ];
};

const readData = (layer, path, callback) => {
  let url;
  const filename = path.split("/").pop();
  const filetype = path.split(".").pop();
  if (filetype === "csv") {
    url = d3.csv(path);
  } else if (filetype === "xml") {
    url = d3.xml(path);
  } else {
    url = d3.json(path);
  }
  url
    .then(
      function (data) {
        // console.log(layer)
        return {
          success: true,
          filename: filename,
          data: data,
          layer: layer,
        };
      },
      function () {
        return { success: false };
      }
    )
    .then(callback);
};

const findLayerBy = (layer, key, value) => {
  if (layer.get(key) === value) {
    return layer;
  }

  // Find recursively if it is a group
  if (layer.getLayers) {
    var layers = layer.getLayers().getArray(),
      len = layers.length,
      result;
    for (var i = 0; i < len; i++) {
      result = findLayerBy(layers[i], key, value);
      if (result) {
        return result;
      }
    }
  }

  return null;
}

const getAllLayers = (layersIn) => {
  console.log(layersIn)
  let layers = [];
  for (let layer of layersIn) {

    // Find recursively if it is a group
    if (layer.getLayers()) {
      var layersInGroup = layer.getLayers().getArray()
      for (let i = 0; i < layersInGroup.length; i++) {
        getAllLayers(layersInGroup[i])
      }
    } else {
      layers.push(layer)
    }
  }

  return layers;
};

export {
  findLayerBy,
  createFeatureFromJSON,
  createFeatureFromCSV,
  getFilenames,
  getFetureLayers,
  getLayersByType,
  getStdDev,
  getMean,
  readData,
  getAllLayers,
};
