// import React from 'react';
// import ReactDOM from 'react-dom';
// import './index.css';
// import App from './App';
// import * as serviceWorker from './serviceWorker';

// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root')
// );


// import React from 'react';
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import PositioningTab from "./Tabs/PositioningTab";
import ProgrammingTab from "./Tabs/ProgrammingTab";

import reportWebVitals from "./reportWebVitals";

const allTabs = [
  {
    link: "/positioning",
    label: "Positioning",
    component: PositioningTab,
  },
  {
    link: "/programming",
    label: "Programming",
    component: ProgrammingTab,
  },
];

ReactDOM.render(<App allTabs={allTabs} />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
