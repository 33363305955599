//// EXTERNAL
import React, { Fragment } from "react";
// import ReactDOM from "react-dom";
import { Switch, Route, Link, BrowserRouter, 
  // Redirect 
} from "react-router-dom";
import { AppBar, Tabs, Tab } from "@material-ui/core";

//// CSS
import './App.css';

function App(props) {
  return (
    <BrowserRouter>
      <div className="App">
        <Route
          path="/"
          render={({ location }) => (
            <Fragment>
              <AppBar position="static">
                <Tabs value={location.pathname}>
                  {props.allTabs.map ((x, i) => <Tab key={i} label={x.label} value={x.link} component={Link} to={x.link} />)}
                </Tabs>
              </AppBar>
              <Switch>
                {props.allTabs.map ((x, i) => <Route key={i} exact path={x.link} component={x.component} />) }
              </Switch>
            </Fragment>
          )}
        />
      </div>
    </BrowserRouter>
  );
}
export default App;