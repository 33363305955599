import React, { useEffect, useState } from "react";
import * as d3 from "d3";
// import _ from "lodash";
// import * as olProj from "ol/proj";
// import { getPointResolution } from "ol/proj";
// import { fromLonLat } from "ol/proj";
// import Feature from "ol/Feature";
// // import Geometry from "ol/geom/Geometry";
// // import Polygon from "ol/geom/Polygon";
// // import MultiPoint from "ol/geom/MultiPoint";
// // import LineString from "ol/geom/LineString";
// import Circle from "ol/geom/Circle";
// import Point from "ol/geom/Point";
// import { pointStyle, circleStyle } from "../Styles/OLStyles";
// import { altKeyOnly, click, pointerMove } from "ol/events/condition";

import { Map } from "../Map";
import { LayersGroup } from "../Layers";
import {
  Scale,
  MapControls,
  FullScreenControl,
  PopupOverlay,
} from "../Controls";

import {
  getFetureLayers,
  createFeatureFromCSV,
} from "../Helpers/helpers";
import "../App.css";

//// IMPORT DATA OBJECT
import layersGroupObject from "./ProgrammingMap_Data";


const ProgrammingTab = () => {
  //// SET MAP STATE. THE MAP WILL BE SET INTHE Map COMPONENT AS MapContext.
  const [map, setMap] = useState(null);
  const center = layersGroupObject.map.center;
  const zoom = layersGroupObject.map.zoom;
  const projID = layersGroupObject.map.projection;

  //// GET AND PROCESS DATA, ADD DATA TO FEATURE LAYERS AND CALCULATED LAYERS IN LAYERS GROUP OBJECT
  const [data, setData] = useState(null);
  const [selectedFeatures, setPopUpFeatures] = useState(null);
  const featureGroups = layersGroupObject.featuregroups;
  const tileGroup = layersGroupObject.tilegroup[0]; ////// need to use [0] as we will not iterate it
  
  const processLayersData = (groups) => {
    // READ DATA WITH D3JS AND ADD FEATURES
    const featureLayers = getFetureLayers(groups);
    featureLayers.forEach((layer) => {
      const dataFolder = layer.get("datafolder");
      const filename = layer.get("filename");
      const title = layer.get("title");

      let url;
      if (filename.split(".")[1] === "csv") {
        url = d3.csv(dataFolder + filename);
      } else if (filename.split(".")[1] === "xml") {
        url = d3.xml(dataFolder + filename);
      } else {
        url = d3.json(dataFolder + filename);
      }
      url
        .then(
          function (data) {
            return {
              success: true,
              title: title,
              filename: filename,
              data: data,
            };
          },
          function () {
            return { success: false };
          }
        )
        .then(function (d) {
          //// ADD DATA TO LAYER AS A PROPERTY
          // console.log(d)
          layer.set("data", d.data);
          const newFeatures = d.data.map((f) => createFeatureFromCSV(f));

          //// ADD FEATURES TO THE LAYER
          layer.getSource().addFeatures(newFeatures);
        });
    });
    return groups;
  };
  useEffect(() => {
    if (map !== null) {
      const d = processLayersData(featureGroups);
      setData(d);
      // setLoading(false);
    }
  }, [map]);

  
  return (
    <div className="container">
      <Map
        center={center}
        projID={projID}
        zoom={zoom}
        onMount={setMap}
        onSelect={setPopUpFeatures}
      >
        {map !== null && (
          <>
            <LayersGroup group={tileGroup} />
            {data !== null && (
              <>
                {data.map((group, index) => {
                  return <LayersGroup key={index} group={group} />;
                })}
              </>
            )}

            <MapControls>
              <FullScreenControl />
              <Scale />
            </MapControls>
            <PopupOverlay feature={selectedFeatures} />
          </>
        )}
      </Map>
    </div>
  );
};

export default ProgrammingTab;
