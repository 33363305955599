import React, { useRef, useState, useEffect } from "react";
import "./Map.css";
import MapContext from "./MapContext";
import * as ol from "ol";

import Select from "ol/interaction/Select";
// import View from 'ol/View';
// import Feature from "ol/Feature";
// import Geometry from "ol/geom/Geometry";
// import Polygon from "ol/geom/Polygon";
// import MultiPoint from "ol/geom/MultiPoint";
// import LineString from "ol/geom/LineString";
// import Circle from "ol/geom/Circle";
// import Point from "ol/geom/Point";
// import Source from "ol/source/Source";
// import OLVectorLayer from "ol/layer/Vector";
// import { fromLonLat, get } from "ol/proj";
// import GeoJSON from "ol/format/GeoJSON";
// import { Style, Icon, Fill, Stroke } from "ol/style";

const Map = ({ children, zoom, center, projectionID, onMount, onSelect }) => {
  const mapRef = useRef();
  const [map, setMap] = useState(null);
  const select = new Select();
  // const [selectedFeatures, setPopUpFeatures] = useState(null);
  //   const [projection, setProjection] = useState();
  //   const [resolutionEq, setResolutionEq] = useState();

  // on component mount
  useEffect(() => {
    let options = {
      view: new ol.View({ zoom, center, projectionID }),
      controls: [],
      overlays: [],
    };

    let mapObject = new ol.Map(options);

    mapObject.addInteraction(select);

    mapObject.setTarget(mapRef.current);
    setMap(mapObject); //// set stae for Map
    onMount(mapObject); //// set state for Tab to use in Layers

    // setProjection(mapObject.getView().getProjection());
    // setResolutionEq(mapObject.getView().getResolution());

    return () => mapObject.setTarget(undefined);
  }, []);

  // zoom change handler
  useEffect(() => {
    if (!map) return;

    map.getView().setZoom(zoom);
  }, [zoom]);

  // center change handler
  useEffect(() => {
    if (!map) return;

    map.getView().setCenter(center);
  }, [center]);

  // select handler
  useEffect(() => {
    const keys = [
      "Project",
      "District",
      "GridRef",
      "Description",
      "Cost",
      "Comp_Date",
      "xCoord",
      "yCoord",
      "Lat",
      "Lon",
    ];

    select.on("select", (e) => {
      // console.log(select.getFeatures());
      if (e.selected.length > 0) {
        console.log(e.selected[0].get("Project"));
        const feature = e.selected[0]
        const featureDescription = keys.map((key) => [key, feature.get(key)]);


        // var featureDescription = {};
        // keys.forEach(function (key, value) {
        //   featureDescription[key] = feature.get(key);
        // });
        onSelect(featureDescription);
      } else {
        onSelect(null);
      }
    });
  }, [select]);

  return (
    <MapContext.Provider value={{ map }}>
      <div ref={mapRef} className="ol-map"></div>
      {children}
    </MapContext.Provider>
  );
};

export default Map;
