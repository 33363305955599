const ZoomToLayer = (props) => {
  return (
    <button className={props.className} onClick={props.onClick}>
      <img
        alt="Locate Layer"
        src="../img/locate-outline.svg"
        style={{ width: "100%", height: "100%" }}
      />
    </button>
  );
};

export default ZoomToLayer;
