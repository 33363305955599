// import { Style, Fill, Stroke, Circle } from "ol/style";
import { Vector as VectorSource } from "ol/source";
import { Vector as VectorLayer, Group } from "ol/layer";
import { pointStyle, 
  // circleStyle 
} from "../Styles/OLStyles";
import OSM from 'ol/source/OSM';
import OLTileLayer from "ol/layer/Tile";

import { fromLonLat } from "ol/proj";
import Feature from "ol/Feature";
// import Geometry from "ol/geom/Geometry";
// import Polygon from "ol/geom/Polygon";
// import MultiPoint from "ol/geom/MultiPoint";
// import LineString from "ol/geom/LineString";
// import Circle from "ol/geom/Circle";
import Point from "ol/geom/Point";


//// LAYERS GROUPS
const layersGroupObject = {
  map:{
    center: fromLonLat([107.17539989514584, 10.492596957779583 ]),
    zoom: 10,
    projection: "EPSG:3857",
    scaleType: "scaleline",
    scaleBarSteps: 4,
    scaleBarText: true,
  
    scaleUnits: 'metric',
    scaleSteps: 4,
    scaleText: true,
    scaleMinWidth: 140,
  },
  tilegroup: [
    new Group({
      title: "Baselayers",
      control: "checkbox",
      selectAll: true,
      selectAllOnly: false,
      visible: true,
      layers: [
        new OLTileLayer({
          source: new OSM(),
          zIndex: 0,
          title: "Open Street Map",
          visibility: true,
        }),
      ],
    }),
  ],
  featuregroups: [
    new Group({
      title: "Melbourne North PM25",
      type: "feature",
      control: "checkbox",
      selectAll: true,
      selectAllOnly: false,
      visible: true,
      layers: [
        new VectorLayer({
          source: new VectorSource({
            attributions: "SMES",
            features: [],
          }),
          datafolder: "./Data/",
          filename: "coords_latlon.csv",
          colors: { stroke: "#000", fill: "#000" },
          zIndex: 3,
          visible: true,
          title: "MGRS coordinates transformed",
          style: pointStyle({ stroke: "#F00", fill: "#0F0", radius: 4 }),
        }),
      ],
    }),
  ],
};

export default layersGroupObject