import { useContext, useEffect } from "react";
import MapContext from "../Map/MapContext";

//// ONLY ADDS LAYERGROUPS WITH LAYERS TO THE MAP, DOESN'T RETURN ANYTHING AND DOESN'T CHANGE LAYERS
const LayersGroup = ({ group }) => {
  const { map } = useContext(MapContext);
  // console.log(group.get("title"), group.getSource().getExtent())
  // console.log(group.get("title"));
  useEffect(() => {
    if (!map) return;
    map.addLayer(group);

    return () => {
      if (map) {
        map.removeLayer(group);
      }
    };
  }, [map, group]);

  return null;
};

export default LayersGroup;
