import { useContext, useEffect } from "react";
import MapContext from "../Map/MapContext";
import ScaleLine from "ol/control/ScaleLine";
import layersGroupObject from "../Tabs/PositioningMap_Data";
const Scale = () => {
  const { map } = useContext(MapContext);
  const mapConfig = layersGroupObject.map;
  useEffect(() => {
    if (!map) return;

    const scaleControl = new ScaleLine({
      units: mapConfig.scaleUnits,
      steps: mapConfig.scaleSteps,
      text: mapConfig.scaleText,
      minWidth: mapConfig.scaleMinWidth,
    });

    map.controls.push(scaleControl);

    return () => map.controls.remove(scaleControl);
  }, [map]);

  return null;
};

export default Scale;
