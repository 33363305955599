const MenuIcon = (props) => {
  return (
    <div className={props.className} onClick={props.onClick}>
      <img src={props.img} alt={props.alt} style={{ width: "100%", height: "100%", color: "#FFF" }}></img>
    </div>

    // className="open_menu"
    //     onClick={(e) => {
    //       e.preventDefault();
    //       toggleMenu(true);
    //     }
  );
};

export default MenuIcon;
