const CheckBox = (props) => {
  return (
    <div className={props.className}>
      <input
        type={props.type}
        checked={props.checked}
        onChange={props.onChange}
      />{" "}
      {props.label}
      
      {props.children}
    </div>
  );
};

export default CheckBox;
